/* ----------------------------------------------- */
/* BASIC SETUP */
/* ----------------------------------------------- */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  background-color: #fff;
  color: #555;
  font-family: 'Lato', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 20px;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}

.clearfix {
  zoom: 1;
}
.clearfix:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

/* ----------------------------------------------- */
/* REUSABLE COMPONENTS */
/* ----------------------------------------------- */

.row {
  max-width: 1140px;
  margin: 0 auto;
}

section {
  padding: 25px 0;
}

.box {
  padding: 1%;
}

/* ----- HEADINGS ----- */
h1,
h2,
h3 {
  font-weight: 300;
  text-transform: uppercase;
}

h1 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #fff;
  font-size: 240%;
  word-spacing: 4px;
  letter-spacing: 1px;
}

h2 {
  font-size: 180%;
  word-spacing: 2px;
  text-align: center;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

h3 {
  font-size: 110%;
  margin-bottom: 15px;
}

h2:after {
  display: block;
  height: 2px;
  background-color: #ff00af;
  content: ' ';
  width: 100px;
  margin: 0 auto;
  margin-top: 15px;
}

.donate--coins {
  line-height: 145%;
  width: 70%;
  margin-left: 15%;
  padding: 20px 0;
}

.donate--address {
  padding-left: 0;
}
.donate--coin {
  padding-left: 10px;
}

/* ----- PARAGRAPHS ----- */
.long-copy {
  line-height: 145%;
  width: 80%;
  margin-left: 10%;
  margin-top: 5px;
}
.manual {
  font-size: 15px;
}

.box p {
  font-size: 90%;
  line-height: 145%;
}

.invisible {
  display: none;
}

/* ----- ICONS ----- */
.icon-big {
  font-size: 350%;
  display: block;
  color: #ff00af;
  margin-bottom: 10px;
}

.icon-small {
  display: inline-block;
  width: 30px;
  text-align: center;
  color: #ff00af;
  font-size: 120%;
  margin-right: 10px;

  /*secrets to align text and icons*/
  line-height: 120%;
  vertical-align: middle;
  margin-top: -5px;
}

/* ----- LINKS ----- */

a:link,
a:visited {
  color: #ff00af;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid #ff00af;
  -webkit-transition: border-bottom 0.2s, color 0.2s;
  transition: border-bottom 0.2s, color 0.2s;
}

a:hover,
a:active {
  color: #555;
  border-bottom: 1px solid transparent;
}

/* ----- BUTTONS NORMAL----- */
.btn:link,
.btn:visited {
  display: inline-block;
  padding: 10px 30px;
  font-weight: 300;
  text-decoration: none;
  border-radius: 200px;
  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
  transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.btn-full:link,
.btn-full:visited {
  background-color: #ff00af;
  border: 1px solid #ff00af;
  color: #fff;
  margin-right: 15px;
}

.btn:hover,
.btn:active {
  background-color: #7e0156;
}

.btn-full:hover,
.btn-full:active {
  border: 1px solid #7e0156;
}

/* ----- BUTTONS SMALL ----- */
.btn-s:link,
.btn-s:visited {
  display: inline-block;
  padding: 5px 15px;
  font-weight: 200;
  text-decoration: none;
  border-radius: 100px;
  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
  transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.btn-full-s:link,
.btn-full-s:visited {
  background-color: #ff00af;
  border: 1px solid #ff00af;
  color: #fff;
  margin-right: 8px;
}

.btn-s:hover,
.btn-s:active {
  background-color: #7e0156;
}

.btn-full-s:hover,
.btn-full-s:active {
  border: 1px solid #7e0156;
}
/* ----------------------------------------------- */
/* HEADER */
/* ----------------------------------------------- */

header {
  background-color: #d2d2d2;
  height: 130px;
}

.logo {
  height: 100px;
  width: auto;
  float: left;
  margin-top: 20px;
}

.logo_defi {
  height: 86px;
  width: auto;
  float: left;
  margin-top: 25px;
  margin-right: 5px;
}

/* Main navi */
.main-nav {
  float: right;
  list-style: none;
  margin-top: 55px;
}

.main-nav li {
  display: inline-block;
  margin-left: 20px;
}

.main-nav li a:link,
.main-nav li a:visited {
  padding: 8px 0;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 90%;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-bottom 0.2s;
  transition: border-bottom 0.2s;
}

.main-nav li a:hover,
.main-nav li a:active {
  border-bottom: 2px solid #ff00af;
}

/* Mobile navi */
.mobile-nav-icon {
  float: right;
  margin-top: 30px;
  cursor: pointer;
  display: none;
}

.mobile-nav-icon i {
  font-size: 200%;
  color: #fff;
}

/*-------------------------------------------------------------------------------------------------*/

.section-converter {
  background-color: #f4f4f4;
}
.section-rewards {
  background-color: #fff;
}
.section-pricefeed {
  background-color: #f4f4f4;
}
.section_roadmap {
  background-color: #fff;
}
.section-aboutme {
  background-color: #f4f4f4;
}

/*MEINE SACHEN AB HIER*/
/* DRAG & DROP AREA */
.drop-area {
  border: 2px solid #ff00af;
  font-size: 100%;
  height: 100px;
  width: 100%;
  text-align: center;
  padding-bottom: 35px;
  padding-top: 35px;
  padding-left: 25%;
  padding-right: 25%;
  border-radius: 20px;
}

/* LOG-MESSAGE */
.output {
  border: 1px solid #ff00af;
  height: 160px;
  font-size: 50%;
  padding: 10px;
  border-radius: 20px;
}

.coin--info--container p {
  font-size: 125%;
  font-weight: 400;
}

/* ----------------------------------------------- */
/* FOOTER */
/* ----------------------------------------------- */

footer {
  background-color: #d2d2d2;
  padding: 50px;
  font-size: 80%;
}

.footer-nav {
  list-style: none;
  float: left;
}

.social-links {
  list-style: none;
  float: right;
}

.footer-nav li,
.social-links li {
  display: inline-block;
  margin-right: 20px;
}

.footer-nav li:last-child,
.social-links li:last-child {
  margin-right: 0;
}

.footer-nav li a:link,
.footer-nav li a:visited,
.social-links li a:link,
.social-links li a:visited {
  text-decoration: none;
  border: 0;
  color: #888;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.footer-nav li a:hover,
.footer-nav li a:active {
  color: #ddd;
}

.social-links li a:link,
.social-links li a:visited {
  font-size: 160%;
}

.ion-social-facebook,
.ion-social-twitter,
.ion-social-linkedin {
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.ion-social-facebook:hover {
  color: #3b5998;
}

.ion-social-twitter:hover {
  color: #00aced;
}

.ion-social-linkedin:hover {
  color: #007bb5;
}

.ion-social-instagram:hover {
  color: #517fa4;
}

footer p {
  color: #000;
  text-align: center;
  margin-top: 20px;
}

/* NAVIGATION */
.nav {
  display: block;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  width: 100%;
  padding: 0 4rem;
  z-index: 100;
}

/* nav and stickly class at the same time */
.nav.sticky {
  position: fixed;
  background-color: #d2d2d2e1;
}

/* ----------------------------------------------- */
/* FORM */
/* ----------------------------------------------- */

select,
textarea {
  width: 100%;
  padding: 7px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

textarea {
  height: 100px;
}

.config--report {
  text-align: left;
}

.config--report select {
  margin-top: -5px;
}

*:focus {
  outline: none;
}

.data-area {
  margin-top: 0px;
}

.file--click {
  opacity: 0;
  height: 0px;
  width: 0px;
}

/*NEUE SACHEN */

.report--zone--container {
  height: 340px;
  border: 1px solid #ff00af;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.report--1 {
  width: 150px;
  margin-top: -13px;
  margin-left: 10px;
  padding-left: 10px;
  background: #f4f4f4;
}

.report--2 {
  width: 200px;
  margin-top: -13px;
  margin-left: 10px;
  padding-left: 10px;
  background: #f4f4f4;
}

.report--zone {
  padding-top: 20px;
}

.report--zone--btn {
  padding-top: 20px;
}
.report--stake--btn {
  margin-top: 50px;
}

.report--cfg {
  width: 100%;
}

.logger {
  font-size: 125%;
  font-weight: 400;
  text-align: center;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  border-bottom: solid 1px#555;
}

/*Sytling of the report tables*/
.js_table_header1 {
  background-color: #ff00af;
  font-size: 15px;
  color: #fff;
}
.js_table_header2 {
  background-color: #ff00af;
  color: #fff;
  font-size: 15px;
}
.js_table {
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  font-family: 'Lato', 'Arial', sans-serif;
  font-size: 12px;
}
.js_table th {
  border-style: solid;
  border-color: #000;
  border-width: 1px;
  width: 40px;
}

.js_table tr {
  border-style: solid;
  border-color: #000;
  border-width: 1px;
}

.js_releasenotes {
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  font-family: 'Lato', 'Arial', sans-serif;
  font-size: 12px;
}
.js_releasenotes th {
  border-style: solid;
  border-color: #000;
  border-width: 1px;
}

.js_releasenotes tr {
  border-style: solid;
  border-color: #000;
  border-width: 1px;
}
.js_table_version {
  width: 90px;
}

.spinner {
  margin: 1rem auto;
  text-align: center;
}

.spinner svg {
  height: 3rem;
  width: 3rem;
  fill: #ff00af;
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* SLIDER */
.slider {
  max-width: 100rem;
  height: 17rem;
  margin: 0 auto;
  position: relative;

  /* IN THE END */
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  margin-top: 15px;
  width: 100%;
  height: 15rem;

  display: flex;
  align-items: center;
  justify-content: center;

  /* THIS creates the animation! */
  transition: transform 1s;
}

.slider__btn {
  position: absolute;
  top: 50%;
  z-index: 10;

  border: none;
  background: rgba(255, 255, 255, 0.7);
  font-family: inherit;
  color: #ff00af;
  border-radius: 50%;
  height: 5.5rem;
  width: 5.5rem;
  font-size: 3.25rem;
  cursor: pointer;
}

.slider__btn--left {
  left: 6%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  right: 6%;
  transform: translate(50%, -50%);
}

.dots {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dots__dot {
  border: none;
  background-color: #b9b9b9;
  opacity: 0.7;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 1.75rem;
  cursor: pointer;
  transition: all 0.5s;

  /* Only necessary when overlying images */
  /* box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7); */
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  /* background-color: #fff; */
  background-color: #888;
  opacity: 1;
}

/* TESTIMONIALS */
.roadmap {
  width: 65%;
  position: relative;
}

.roadmap__header {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.roadmap__text {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  color: #666;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #ff00af;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tester {
  text-align: left;
}

.pricefeed_table {
  margin-left: 15%;
  text-align: left;
}

.ticker {
  width: 140px;
}
.price {
  width: 120px;
}

/*MEDIA QUERIES*/
@media only screen and (max-width: 1280px) {
  header {
    height: 80px;
  }
  .logo {
    height: 50px;
  }
  .logo_defi {
    height: 43px;
    margin-top: 21px;
    margin-right: 2px;
  }
  .main-nav {
    margin-top: 30px;
    font-size: 80%;
  }

  .main-nav li {
    margin-left: 10px;
  }
  .cur-sel {
    margin-left: 5px;
    width: 45px;
  }

  .nav {
    height: 4rem;
  }
  .slider {
    height: 16rem;
  }
  .slider__btn {
    font-size: 2rem;
    height: 2rem;
    width: 3rem;
  }
  .dots__dot {
    height: 1rem;
    width: 1rem;
  }
  .roadmap__header {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  .roadmap__text {
    font-size: 0.65rem;
  }
  .long-copy {
    font-size: 80%;
  }
  h2 {
    font-size: 90%;
  }
  select,
  textarea {
    width: 68%;
    padding: 6px;
    font-size: 80%;
  }
  select {
    margin-left: -40px;
  }
  label {
    font-size: 75%;
  }
  textarea {
    height: 100px;
  }
  .container {
    padding-left: 30px;
    font-size: 20px;
  }
  .report--zone--btn {
    padding-top: 20px;
    margin-right: 1rem;
  }
  .report--1 {
    width: 150px;
    margin-top: -20px;
    margin-left: 10px;
    padding-left: 10px;
    background: #f4f4f4;
  }
  .report--2 {
    width: 200px;
    margin-top: -20px;
  }
  .report--zone--container {
    padding-left: 10px;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  header {
    height: 70px;
  }
  .logo {
    height: 25px;
  }
  .logo_defi {
    height: 21.5px;
    margin-top: 21px;
    margin-right: 2px;
  }
  .main-nav {
    margin-top: 27px;
    font-size: 41%;
  }

  .main-nav li {
    margin-left: 3px;
  }

  .cur-sel {
    margin-left: 3px;
    width: 40px;
  }

  .nav {
    height: 3rem;
  }
  .slider {
    height: 16rem;
  }
  .slider__btn {
    font-size: 1.5rem;
    height: 2.5rem;
    width: 2.5rem;
  }
  .dots__dot {
    height: 0.75rem;
    width: 0.75rem;
  }
  .roadmap__header {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .roadmap__text {
    font-size: 0.5rem;
  }
  .long-copy {
    font-size: 80%;
  }
  h2 {
    font-size: 90%;
  }
  select,
  textarea {
    width: 75%;
    padding: 6px;
    font-size: 80%;
  }
  select {
    margin-left: -40px;
  }
  label {
    font-size: 75%;
  }
  textarea {
    height: 100px;
  }
  .container {
    padding-left: 35px;
    margin-bottom: 12px;
    font-size: 16px;
  }
  .report--zone--btn {
    padding-top: 5px;
    margin-right: 4.5rem;
  }
  .report--stake--btn {
    margin-top: 20px;
  }
  .report--1 {
    width: 150px;
    margin-top: -22px;
    margin-left: 10px;
    padding-left: 10px;
    background: #f4f4f4;
  }
  .report--2 {
    width: 200px;
    margin-top: -22px;
  }

  .report--zone--container {
    height: 280px;
    border: 1px solid #ff00af;
    padding-left: 5px;
    padding-top: 10px;
  }
  .donate--coins {
    font-size: 60%;
  }
}
@media only screen and (max-width: 480px) {
  /* For mobile phones: */
  .logo {
    height: 25px;
    display: none;
  }
  header {
    height: 110px;
  }
  .logo_defi {
    height: 21.5px;
    margin-top: 21px;
    margin-right: 1px;
  }
  .main-nav {
    margin-top: 27px;
    font-size: 35%;
  }

  .main-nav li {
    margin-left: 1px;
  }
  .cur-sel {
    margin-left: 1px;
    width: 40px;
  }

  .nav {
    height: 5rem;
  }
  .slider {
    height: 16rem;
  }
  .slider__btn {
    font-size: 1.25rem;
    height: 2.5rem;
    width: 2.5rem;
  }
  .dots__dot {
    height: 0.5rem;
    width: 0.5rem;
  }
  .roadmap__header {
    font-size: 0.6rem;
    margin-bottom: 0.5rem;
  }
  .roadmap__text {
    font-size: 0.4rem;
  }
  .long-copy {
    line-height: 145%;
    width: 70%;
    margin-left: 15%;
    margin-top: 5px;
    font-size: 70%;
  }
  h2 {
    font-size: 90%;
  }
  select,
  textarea {
    width: 25%;
    padding: 7px;
  }

  textarea {
    height: 90px;
  }
  .report--zone--btn {
    padding-top: 5px;
  }
  .report--stake--btn {
    margin-top: 20px;
  }
  .report--1 {
    width: 150px;
    margin-top: -22px;
    margin-left: 10px;
    padding-left: 10px;
    background: #f4f4f4;
  }
  .report--2 {
    width: 200px;
    margin-top: -22px;
  }

  .report--zone--container {
    height: 340px;
    border: 1px solid #ff00af;
    padding-left: 20px;
    padding-top: 10px;
  }
  .donate--coins {
    font-size: 60%;
  }
}

@media only screen and (max-width: 420px) {
  /* For mobile phones: */
  .logo {
    height: 25px;
    display: none;
  }
  header {
    height: 110px;
  }
  .logo_defi {
    height: 21.5px;
    margin-top: 21px;
    margin-right: 2px;
  }
  .main-nav {
    margin-top: 27px;
    font-size: 35%;
  }

  .main-nav li {
    margin-left: 1px;
  }

  .nav {
    height: 5rem;
  }
  .slider {
    height: 16rem;
  }
  .slider__btn {
    font-size: 1.25rem;
    height: 2.5rem;
    width: 2.5rem;
  }
  .dots__dot {
    height: 0.5rem;
    width: 0.5rem;
  }
  .roadmap__header {
    font-size: 0.6rem;
    margin-bottom: 0.5rem;
  }
  .roadmap__text {
    font-size: 0.5rem;
  }
  .long-copy {
    line-height: 145%;
    width: 70%;
    margin-left: 15%;
    margin-top: 5px;
    font-size: 70%;
  }
  h2 {
    font-size: 90%;
  }
  select,
  textarea {
    width: 30%;
    padding: 7px;
  }
  textarea {
    height: 90px;
  }
  select {
    margin-left: -5px;
  }
  label {
    font-size: 80%;
  }
  .report--zone--btn {
    padding-top: 5px;
  }
  .report--stake--btn {
    margin-top: 20px;
  }
  .report--1 {
    width: 150px;
    margin-top: -22px;
    margin-left: 10px;
    padding-left: 10px;
    background: #f4f4f4;
  }
  .report--2 {
    width: 200px;
    margin-top: -22px;
  }

  .report--zone--container {
    height: 340px;
    border: 1px solid #ff00af;
    padding-left: 20px;
    padding-top: 10px;
  }
  .donate--coins {
    font-size: 60%;
  }
}

.reward_select {
  width: 140px;
  margin-left: 20px;
}

.cur-sel {
  background-color: #d2d2d2;
}
